:root {
  --easeInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --border: whitesmoke;
  --card: white;
  --font-size: 1rem;
  --font-width: lighter;
  --font-color: lighten(black, 20%);
}

.flipClock {
  display: flex;
  justify-content: space-between;
  color: black;
}

.flipUnitContainer {
  display: block;
  position: relative;
  width: 24px;
  height: auto;
  perspective-origin: 50% 50%;
  perspective: 300px;
  background-color: var(--card);
  border-radius: 3px;
  box-shadow: 0px 10px 10px -10px grey;
}

.upperCard,
.lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.upperCard span,
.lowerCard span {
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: var(--font-width);
  color: var(--font-color);
}

.upperCard {
  align-items: flex-end;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #efefef;
}
.upperCard span {
  transform: translateY(50%);
}
.lowerCard {
  align-items: flex-start;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.lowerCard span {
  transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  backface-visibility: hidden;
}

.flipCard span {
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-width);
  color: var(--font-color);
}
.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  transform-origin: 50% 0%;
  transform: rotateX(180deg);
  background-color: var(--card);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.flipCard.unfold span {
  transform: translateY(-50%);
}

.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  transform-origin: 50% 100%;
  transform: rotateX(0deg);
  background-color: var(--card);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.flipCard.fold span {
  transform: translateY(50%);
}
.fold {
  animation: fold 0.6s var(--easeInOut);
  transform-style: preserve-3d;
}

.unfold {
  animation: unfold 0.6s var(--easeInOut);
  transform-style: preserve-3d;
}
@keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
