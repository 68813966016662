@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .pageTitle {
    @apply text-xl font-semibold  p-0 m-0 uppercase;
  }
  .sectionTitle {
    @apply text-base capitalize tracking-wide font-semibold;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  padding: 0;
  margin: 0;
}

ul,
#myUL {
  list-style-type: none;
}

.caret {
  cursor: pointer;
  user-select: none;
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  transform: rotate(90deg);
}

.nested {
  display: none;
}

.active {
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
